.Cards{
  width: 100%;

}

.CardGroup {
 
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 45px 15px;
  justify-items: center;
  margin-bottom: 100px;
}

.Card {
  width: 90%;
  
  border-radius: 20px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.15);
  background-color: rgba(50,50,50,0.4);
  backdrop-filter: blur(30px);

  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
  cursor: pointer;
  
    display: flex;
    flex-direction: column;
     justify-items: space-around;
}

.Footer {
  
  width: 100%;
  
}

.Header {
  min-height: 50px;
  display: flex;
  flex-direction: column;
  justify-content:  flex-start;
  align-items: flex-start;
  padding-top: 10px;
  padding-left: 15px;
  border: 0px solid red;
}

.Content {
   flex: auto;
}



.Card img {
  width: 95%;
  height: 200px;
  object-fit: cover;
  margin: 0px;
  margin-top: 0px;
  border-radius: 10px;
  box-shadow: 0 20px 30px -15px rgba(0, 0, 0, 0.4);
}



.Cards h2 {
  margin: 50px 20px;
  font-size: px;
  text-align: center;
  font-weight: 700;
  background: linear-gradient(104.74deg, #dcebdb 0%, #993f0f 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.Card h3 {
  color: white;
  font-size: 24px;
  margin: 20px 10px 10px 20px;
  text-align: left;
}

.Card p {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 600;
  font-size: 14px;
  text-align: left;
  margin: 0px;
  padding: 15px;
  overflow: hidden;
  line-height: 1.55;
  
}

.CheckInBadge {
  background: rgb(222, 91, 110);
  background: linear-gradient(
    90deg,
    rgba(222, 91, 110, 1) 0%,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.4) 64%,
    rgba(255, 255, 255, 1) 100%
  );
  border-radius: 10px;
  margin: 10px;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1.7fr 0.5fr;
  gap: 0px 0px;
  align-items: center; 

  box-shadow: 0 20px 30px -15px rgba(0, 0, 0, 0.15);
  transition: 0.8s cubic-bezier(0.2, 0.8, 0.2, 1);
}

.CheckInBadge p {
  margin: 0;
}

.CheckInBadge svg{
  margin: 0;
}


.Card hr.solid {
  border: none;
  height: 20px;
  width: 100%;
  height: 50px;
  margin-top: 0;
  border: 0;
  box-shadow: 0 20px 30px -15px rgba(0, 0, 0, 0.15);
  /* 	box-shadow: 0 20px 20px -20px #333;
 */
  margin: -50px auto 10px;
}





@media(max-width: 340px){
  
  .Card {
  width: 300px;
}
}



@media(min-width: 720px){

  
  .Cards{ padding-top: 30px; }
  .CardGroup {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media(min-width: 900px){

    .Card:hover {
  transform: scale(1.05, 1.05);
  box-shadow: 0 30px 60px rgba(0, 0, 0, 0.5);
}

  .CardGroup {
    grid-template-columns: repeat(3, 1fr);
  }
}
