.App {
  text-align: center;
  overflow-x: hidden;
  overflow: hidden;
  padding-left: (env(safe-area-inset-left));
  padding-right: (env(safe-area-inset-right));

}




.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 5.5s linear;
  }
}

.App-header {
  /*background-color: #6696ca; */
  /*
  background: rgb(222,91,110);
  background: linear-gradient(
                344deg, 
                rgba(222,91,110,1) 0%, 
                rgba(71,139,162,1) 100%);*/
  background: url(images/background.jpg);
  background-size: cover;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:flex-end;
  font-size: calc(10px + 2vmin);

}

.App-header h1{
  color: white;
}

.App-header .logo{
  width: 100%;
}

 @media(min-width: 600px){
  .App-header .logo{
  width: 300px;
}
 }

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

